import Document from './Document';

export default {
	elementHasClass(element, cls) {
		return elementHasClass(element, cls);
	},
	elementVisibleInVerticalViewport(element) {
		return elementVisibleInVerticalViewport(element);
	},
	haveParentsGotClass(childElement, cls) {
		return haveParentsGotClass(childElement, cls);
	}
}

function elementHasClass(element, cls) {
	return element.className.match(new RegExp('(\\s|^)'+ cls +'(\\s|$)'));
}

function elementVisibleInVerticalViewport(element) {
	let elementHeight = element.offsetHeight,
		viewportHeight = Document.viewportSize().height,
		rect = element.getBoundingClientRect(),
		top = rect.top,
		bottom = rect.bottom;

	return Math.max(0, top > 0 ? Math.min(elementHeight, viewportHeight - top) : Math.min(bottom, viewportHeight));
}

function haveParentsGotClass(childElement, cls) {
	if(childElement.className.split(' ').indexOf(cls) >= 0) {
		return true;
	}

	try {
		//Throws TypeError if child doesn't have parent any more
		return childElement.parentNode && haveParentsGotClass(childElement.parentNode, cls);
	} catch(TypeError) {
		return false;
	}
}