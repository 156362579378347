import Elements from '../helpers/Elements';

export default {
	init() {
		setupOCMToggles();
		setupOCMEvents();
		setupNotificationsOCMToggles();
		setupNotificationsOCMEvents();
	},
	finalize() {
		// Fires after page specific js
	}
}

let animSpeed = 700;

function setupOCMToggles() {
	let toggles = document.getElementsByClassName('ocm-toggle');

	if (toggles.length > 0) {
		// Loop over all ocm toggles and listen for the click event and either show or hide the ocm dependant on if its already open
		for (let i = toggles.length - 1; i >= 0; i--) {
			if (!Elements.elementHasClass(toggles[i], 'setup-ocm-toggle')) {
				toggles[i].onclick = (e) => {
					let open = Elements.elementHasClass(document.body, 'ocm-open'),
						notificationsOpen = Elements.elementHasClass(document.body, 'notifications-ocm-open');

					e.preventDefault();

					if (Elements.elementHasClass(document.body, 'animating')) {
						return;
					}

					if (notificationsOpen) {
						closeNotificationOCM();

						setTimeout(() => {
							openOCM();
						}, animSpeed + 100);
					} else {
						if (open) {
							closeOCM();
						} else {
							openOCM();
						}
					}
				}

				toggles[i].classList.add('setup-ocm-toggle');
			}
		}
	}

	document.addEventListener('closing-ocm', () => {
		document.removeEventListener('click', closeOnClickOCM);
	});

	document.addEventListener('opened-ocm', () => {
		document.addEventListener('click', closeOnClickOCM);
	});
}

function setupNotificationsOCMToggles() {
	let toggles = document.getElementsByClassName('notifications-ocm-toggle');

	if (toggles.length > 0) {
		// Loop over all ocm toggles and listen for the click event and either show or hide the ocm dependant on if its already open
		for (let i = toggles.length - 1; i >= 0; i--) {
			if (!Elements.elementHasClass(toggles[i], 'setup-notifications-ocm-toggle')) {
				toggles[i].onclick = (e) => {
					let open = Elements.elementHasClass(document.body, 'notifications-ocm-open'),
						ocmOpen = Elements.elementHasClass(document.body, 'ocm-open');

					e.preventDefault();

					if (Elements.elementHasClass(document.body, 'animating')) {
						return;
					}

					if (ocmOpen) {
						closeOCM();

						setTimeout(() => {
							openNotificationOCM();
						}, animSpeed + 100);
					} else {
						if (open) {
							closeNotificationOCM();
						} else {
							openNotificationOCM();
						}
					}
				}

				toggles[i].classList.add('setup-notifications-ocm-toggle');
			}
		}
	}

	document.addEventListener('closing-notifications-ocm', () => {
		document.removeEventListener('click', closeOnClickNotificationsOCM);
	});

	document.addEventListener('opened-notifications-ocm', () => {
		document.addEventListener('click', closeOnClickNotificationsOCM);
	});
}

function openOCM() {
	let openingEvent = new CustomEvent('opening-ocm'),
		openedEvent = new CustomEvent('opened-ocm');

	document.dispatchEvent(openingEvent);

	document.body.classList.add('animating');
	document.body.classList.add('ocm-open');

	setTimeout(() => {
		document.body.classList.remove('animating');
		document.dispatchEvent(openedEvent);
	}, animSpeed);
}

function closeOCM() {
	let closingEvent = new CustomEvent('closing-ocm'),
		closedEvent = new CustomEvent('closed-ocm');

	document.dispatchEvent(closingEvent);

	document.body.classList.add('animating');
	document.body.classList.remove('ocm-open');

	setTimeout(() => {
		document.body.classList.remove('animating');
		document.dispatchEvent(closedEvent);
	}, animSpeed);
}

function openNotificationOCM() {
	let openingEvent = new CustomEvent('opening-notifications-ocm'),
		openedEvent = new CustomEvent('opened-notifications-ocm');

	document.dispatchEvent(openingEvent);

	document.body.classList.add('animating');
	document.body.classList.add('notifications-ocm-open');

	setTimeout(() => {
		document.body.classList.remove('animating');
		document.dispatchEvent(openedEvent);
	}, animSpeed);
}

function closeNotificationOCM() {
	let closingEvent = new CustomEvent('closing-notifications-ocm'),
		closedEvent = new CustomEvent('closed-notifications-ocm');

	document.dispatchEvent(closingEvent);

	document.body.classList.add('animating');
	document.body.classList.remove('notifications-ocm-open');

	setTimeout(() => {
		document.body.classList.remove('animating');
		document.dispatchEvent(closedEvent);
	}, animSpeed);
}

function closeOnClickOCM(e) {
	let ocm = document.getElementById('ocm'),
		isClickInsideElement = ocm.contains(e.target);

	if (!isClickInsideElement) {
		closeOCM();
	}
}

function closeOnClickNotificationsOCM(e) {
	let ocm = document.getElementById('notifications-ocm'),
		isClickInsideElement = ocm.contains(e.target);

	if (!isClickInsideElement) {
		closeNotificationOCM();
	}
}

function setupOCMEvents() {
	window.addEventListener('close-ocm', () => {
		closeOCM();
	});

	window.addEventListener('open-ocm', () => {
		openOCM();
	});
}

function setupNotificationsOCMEvents() {
	window.addEventListener('close-notifications-ocm', () => {
		closeNotificationOCM();
	});

	window.addEventListener('open-notifications-ocm', () => {
		openNotificationOCM();
	});

	window.addEventListener('close-remove-notifications-ocm', () => {
		let toggles = document.getElementsByClassName('notifications-ocm-toggle');

		if (toggles) {
			while(toggles.length > 0){
				toggles[0].parentNode.removeChild(toggles[0]);
			}
		}

		closeNotificationOCM();

		setTimeout(() => {
			let ocm = document.getElementById('notifications-ocm');

			if (ocm) {
				ocm.remove();
			}
		}, animSpeed);
	});
}