import './bootstrap';

import Router from './helpers/Router';
import Document from './helpers/Document';

// Routes to register
import common from './routes/common';
import webapp from './routes/webapp';
import supportRequests from './routes/support-requests';
import reporting from './routes/reports';
import MyAppraisals from './routes/my-appraisals';

const routes = new Router({
	// All pages
	common,
	webapp,
	supportRequests,
	reporting,
	MyAppraisals
})

Document.documentReady(() => {
	routes.loadEvents();
});
