import Elements from "../helpers/Elements";

export default {
	init() {
		setHeightVar();
		setNoticeBarHeightVar();
		setSidebarHeightVar();
		setVisibleNoticeBarHeightInViewport();
		setVisibleSidebarHeightInViewport();
		setWebAppVisibleSidebarAndNoticeBar();

		document.addEventListener('update-banner-height', (e) => {
			setNoticeBarHeightVar();
			setVisibleNoticeBarHeightInViewport();
			setWebAppVisibleSidebarAndNoticeBar();
		});

		window.addEventListener('resize', () => {
			setHeightVar();
			setNoticeBarHeightVar();
			setSidebarHeightVar();
			setVisibleNoticeBarHeightInViewport();
			setVisibleNoticeBarHeightInViewport();
			setWebAppVisibleSidebarAndNoticeBar();
		});

		window.addEventListener('scroll', () => {
			setVisibleNoticeBarHeightInViewport();
			setVisibleNoticeBarHeightInViewport();
			setWebAppVisibleSidebarAndNoticeBar();
		})
	}
}

function setHeightVar() {
	let header = document.getElementById('header'),
		height = header.offsetHeight;

	document.documentElement.style.setProperty('--header-height', height + 'px'); // allows us to use the height in CSS e.g var(--header-height);
}

function setNoticeBarHeightVar() {
	let notice = document.getElementById('site-banner'),
		height = notice ? notice.offsetHeight : 0;

	document.documentElement.style.setProperty('--site-notice-bar-height', height + 'px'); // allows us to use the height in CSS e.g var(--site-notice-bar-height);
}

function setVisibleNoticeBarHeightInViewport() {
	let notice = document.getElementById('site-banner'),
		height = 0;

	if (notice) {
		height = Elements.elementVisibleInVerticalViewport(notice);
	}

	document.documentElement.style.setProperty('--site-notice-bar-visible-height', height + 'px'); // allows us to use the height in CSS e.g var(--site-notice-bar-visible-height);
}

function setSidebarHeightVar() {
	let sidebar = document.getElementById('web-app-sidebar'),
		height = sidebar ? sidebar.offsetHeight : 0;

	document.documentElement.style.setProperty('--web-app-sidebar-height', height + 'px'); // allows us to use the height in CSS e.g var(--web-app-sidebar-height);
}

function setVisibleSidebarHeightInViewport() {
	let sidebar = document.getElementById('web-app-sidebar'),
		height = 0;

	if (sidebar) {
		height = Elements.elementVisibleInVerticalViewport(sidebar);
	}

	document.documentElement.style.setProperty('--web-app-sidebar-visible-height', height + 'px'); // allows us to use the height in CSS e.g var(--web-app-sidebar-visible-height);
}

function setWebAppVisibleSidebarAndNoticeBar() {
	let notice = document.getElementById('site-banner'),
		sidebar = document.getElementById('web-app-sidebar'),
		height = 0;

	if (sidebar) {
		height = Elements.elementVisibleInVerticalViewport(sidebar);
	}

	if (notice) {
		height = height + Elements.elementVisibleInVerticalViewport(notice);
	}

	document.documentElement.style.setProperty('--web-app-sidebar-notice-bar-visible-height', height + 'px'); // allows us to use the height in CSS e.g var(--web-app-sidebar-visible-height);
}