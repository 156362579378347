let Accordion = require('vanilla-js-accordion');

export default {
	init() {
		setupAccordions();
	}
}

function setupAccordions() {
	let accordions = document.getElementsByClassName('accordion');

	if (accordions) {
		for (let accordion of accordions) {
			new Accordion(accordion, {
				allowMultiple: false
			});
		}
	}
}