import Elements from '../helpers/Elements';

export default {
	init() {
		setupSupportRowTriggers();
		window.addEventListener('updated-appraisal-rows', (e) => {
			setupSupportRowTriggers();
		});
	},
	finalize() {
		// Fires after page specific js
	}
}

function setupSupportRowTriggers() {
	let rows = document.getElementsByClassName('appraisal-row');

	if (rows.length > 0) {
		for (let i = 0 ; i < rows.length; i++) {
			if (!Elements.elementHasClass(rows[i], 'setup-js-trigger')) {
				setupRowTrigger(rows[i]);
			}
		}
	}
}

function setupRowTrigger(row) {
	let url = row.dataset.url;

	if (url) {
		row.addEventListener('click', (event) => {
			if (!Elements.haveParentsGotClass(event.target, 'ignore-parent-click')) {
				window.location.href = url;
			}
		});

		row.addEventListener('keypress', (event) => {
			if (event.code === 'Enter') {
				row.click();
			}
		});

		row.classList.add('setup-js-trigger');
	}
}