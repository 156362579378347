export default {
	documentReady(fn) {
		documentReady(fn);
	},
	viewportSize() {
		return viewportSize();
	}
}

function documentReady(fn) {
	// see if DOM is already available
	if (document.readyState === 'complete' || document.readyState === 'interactive') {
		// call on next available tick
		setTimeout(fn, 1);
	} else {
		document.addEventListener('DOMContentLoaded', fn);
	}
}

/*
 * Returns the viewport size, including scrollbars - which matches media queries
 *
 * http://andylangton.co.uk/blog/development/get-viewport-size-width-and-height-javascript
 */
function viewportSize() {
	let e = window, a = 'inner';
	if (!( 'innerWidth' in window)) {
		a = 'client';
		e = document.documentElement || document.body;
	}
	return {width: e[a + 'Width'], height: e[a + 'Height']};
};