import DocumentVariables from '../global/document-variables';
import Accordion from '../global/accordion';

export default {
	init() {
		DocumentVariables.init();
		Accordion.init();
	},
	finalize() {
		// Fires after page specific js
	}
}